import { Box, Container, Flex, Link, Stack } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { VacancyCard } from "./components/VacancyCard";
import { useTranslation } from "react-i18next";
import { shuffleArray } from "../../helpers/shuffleArray";

interface VacancyState {
  alternate_url: string;
  found: number;
  items: Array<any>;
}

export const Vacancy = () => {
  const { t } = useTranslation();
  const [vacancy, setVacancyState] = useState<VacancyState>();

  useEffect(() => {
    const apiUrl =
      "https://api.hh.ru/vacancies?from=employerPage&employer_id=10336309";
    axios.get(apiUrl).then((resp) => {
      const allPersons = resp.data;
      setVacancyState(allPersons);
    });
  }, [setVacancyState]);

  const getVacancy = () => {
    const onlyOpenVacancy =
      vacancy?.items.filter(({ type }) => type.id === "open") || [];

    return shuffleArray(onlyOpenVacancy).slice(-6) || [];
  };

  return (
    <Box pb="64px">
      <Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
        <Link
          color={"gray.600"}
          fontSize={{ base: "sm", sm: "lg" }}
          target="_blank"
          href="https://spb.hh.ru/search/vacancy?from=employerPage&employer_id=10336309"
        >
          {t("Vacancy.moreInfoByLink")} {"->"}
        </Link>
      </Stack>
      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {getVacancy().map((item) => (
            <Box key={item.id}>
              <VacancyCard item={item} />
            </Box>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};
