import React from "react";
import cassandra from "../../images/cassandra.png";
import docker from "../../images/docker.png";
import elasticsearch from "../../images/elasticsearch.png";
import hbase from "../../images/hbase.svg";
import java from "../../images/java.png";
import kafka from "../../images/kafka.png";
import kubernetes from "../../images/kubernetes.png";
import node from "../../images/node.png";
import phyton from "../../images/phyton.png";
import postgres from "../../images/postgres.png";
import react from "../../images/react.png";
import rest from "../../images/rest.png";
import vue from "../../images/vue.png";
import sass from "../../images/sass.png";
import js from "../../images/js.png";
import testing from "../../images/testing.png";
import { Box, Container, Flex, chakra } from "@chakra-ui/react";
import { shuffleArray } from "../../helpers/shuffleArray";
import { FlipIconCard } from "./components/FlipIconCard";

const logoArray = [
  cassandra,
  docker,
  elasticsearch,
  hbase,
  java,
  kafka,
  kubernetes,
  node,
  phyton,
  postgres,
  react,
  rest,
  vue,
  sass,
  js,
  testing,
];

const LogoArrayFirst = shuffleArray(logoArray).slice(8);
const LogoArraySecond = shuffleArray(logoArray).slice(8);
const arrayOfSix = Array.from(Array(8).keys());

export const Technologies = () => {
  return (
    <Box p={4}>
      <Container maxW={"7xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          {arrayOfSix.map((item) => (
            <FlipIconCard
              key={item}
              iconFront={<chakra.img src={LogoArrayFirst[item]} />}
              iconBack={<chakra.img src={LogoArraySecond[item]} />}
            />
          ))}
        </Flex>
      </Container>
    </Box>
  );
};
