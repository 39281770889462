import React, { useState, useEffect } from "react";
import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { FcUp } from "react-icons/fc";

export const ScrollToTopButton = () => {
  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <Box
      display={offset > 100 ? "block" : "none"}
      position="fixed"
      zIndex="999"
      bottom="70px"
      right="10px"
      textAlign="center"
    >
      <Flex
        w={16}
        h={16}
        align={"center"}
        justify={"center"}
        color={"white"}
        rounded={"full"}
        cursor="pointer"
        bg={useColorModeValue("gray.200", "gray.900")}
        onClick={handleScrollToTop}
      >
        <FcUp />
      </Flex>
    </Box>
  );
};
