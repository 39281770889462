import React, { ReactElement } from "react";

import { Box, Text } from "@chakra-ui/react";

interface BackCardProps {
  heading?: string;
  description?: string;
  icon?: ReactElement;
}

export const BackCard = ({ heading, description, icon }: BackCardProps) => {
  return (
    <Box
      w="275px"
      h="275px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
      display={"flex"}
      alignItems={"center"}
    >
      <Text mt={1} fontSize={"sm"}>
        {description}
      </Text>
    </Box>
  );
};
