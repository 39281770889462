import { Box, Flex, useColorModeValue } from "@chakra-ui/react";
import { ColorModeSwitcher } from "../../components/ColorModeSwitcher";
import { Logo } from "../../components/Logo";
import { LanguageSelector } from "../../components/LanguageSelector";

export const Header = () => {
  return (
    <>
      <Box
        bg={useColorModeValue("gray.100", "gray.900")}
        px={4}
        position="fixed"
        top={0}
        left={0}
        right={0}
        zIndex={10}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <Logo sx={{ w: "36px", h: "36px" }} />
          <Flex>
            <LanguageSelector />
            <ColorModeSwitcher justifySelf="flex-end" />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
