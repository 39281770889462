import React, { Fragment, ReactElement } from "react";
import { FlipCard } from "../../../components/Flipper/FlipCard";
import { FrontCard } from "./FrontCard";
import { BackCard } from "./BackCard";

interface ServicesCardProp {
  heading: string;
  description: string;
  icon: ReactElement;
}

export const ServicesCard = ({
  heading,
  icon,
  description,
}: ServicesCardProp) => {
  return (
    <>
      <FlipCard
        FrontSide={<FrontCard heading={heading} icon={icon} />}
        BackSide={<BackCard description={description} />}
      />
    </>
  );
};
