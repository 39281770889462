import * as React from "react";
import {
  chakra,
  ImageProps,
  forwardRef,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logo from "../icons/logo.svg";

export const Logo = forwardRef<ImageProps, "img">((props, ref) => {
  return (
    <Flex>
      <Text
        fontSize="2xl"
        fontWeight="bold"
        color={useColorModeValue("gray.800", "#009ab5")}
      >
        Matrix Systems
      </Text>
      <chakra.img src={logo} ref={ref} {...props} />
    </Flex>
  );
});
