import React from "react";

import { Box } from "@chakra-ui/react";
import { Header } from "./header/Header";
import { Footer } from "./footer/Footer";
import { MainContent } from "./MainContent";

import { ScrollToTopButton } from "../components/ScrollToTop";

export const MainPage = () => {
  return (
    <Box
      maxH="100vh"
      textAlign="center"
      fontSize="xl"
      height="100vh"
      overflow={["visible", "visible", "auto"]}
      scrollSnapType={["none", "none", "y mandatory"]}
    >
      <Header />
      <MainContent />
      <Footer />
      <ScrollToTopButton />
    </Box>
  );
};
