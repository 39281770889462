import { Flex, FlexProps, Heading } from "@chakra-ui/react";
import React, { ReactNode } from "react";

type ContentBlockProps = {
  children: ReactNode;
  label: string;
};

export const ContentBlock = ({
  children,
  label,
  ...rest
}: ContentBlockProps & FlexProps) => {
  return (
    <Flex flexDirection={"column"}>
      <Heading lineHeight="tall" p={10} color="#009ab5">
        {label}
      </Heading>
      <Flex {...rest} m="0 auto" pt="4vh" pb={"4vh"} minH={"100%"}>
        {children}
      </Flex>
    </Flex>
  );
};
