import React, { ReactElement } from "react";

import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

interface CardProps {
  heading?: string;
  description?: string;
  icon?: ReactElement;
}

export const Card = ({ heading, description, icon }: CardProps) => {
  return (
    <Box
      w="275px"
      h="275px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={"start"} spacing={2}>
        <Flex
          w={icon ? 16 : 0}
          h={icon ? 16 : 0}
          align={"center"}
          justify={"center"}
          color={"white"}
          rounded={"full"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md" color="#009ab5">
            {heading}
          </Heading>
          <Text mt={1} fontSize={"sm"}>
            {description}
          </Text>
        </Box>
      </Stack>
    </Box>
  );
};
