import React from "react";

import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  chakra,
  useColorModeValue,
} from "@chakra-ui/react";
import hhLogo from "../../../images/HeadHunter_logo.png";
import { useTranslation } from "react-i18next";

interface VacancyCardProps {
  item: {
    name: string;
    area: {
      name: string;
    };
    employment: {
      name: string;
    };
    experience: {
      name: string;
    };
    schedule: {
      name: string;
    };
    snippet: { requirement: string; responsibility: string };
    alternate_url: string;
    apply_alternate_url: string;
  };
}

export const VacancyCard = ({ item }: VacancyCardProps) => {
  const { t } = useTranslation();

  return (
    <Box
      maxW={{ base: "full", md: "275px" }}
      w={"full"}
      h="100%"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack
        align={"start"}
        spacing={2}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        h="100%"
      >
        <Flex
          w={"100%"}
          h={16}
          align={"center"}
          m={2}
          bg={useColorModeValue("gray.100", "gray.700")}
          rounded={"full"}
        >
          <chakra.img src={hhLogo} h="52px" w="52px" mr="10px" />
          <Heading w={"75%"} size="md" noOfLines={2}>
            {item.name}
          </Heading>
        </Flex>
        <Box mt={2} textAlign={"start"}>
          <Text mt={1} fontSize={"sm"}>
            {item.snippet.responsibility}
          </Text>
          <Button
            variant={"link"}
            colorScheme={"blue"}
            size={"sm"}
            onClick={() => window.open(`${item.alternate_url}`, "_blank")}
          >
            {t("Vacancy.learnMore")}
          </Button>
        </Box>
        <Box textAlign={"center"} w={"100%"} mt={2}>
          <Button
            colorScheme={"green"}
            size={"md"}
            onClick={() => window.open(`${item.apply_alternate_url}`, "_blank")}
          >
            {t("Vacancy.respond")}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
