import React, { ReactNode } from "react";

import { ContentBlock } from "./layout/ContentBlock";
import { useTranslation } from "react-i18next";
import { AboutUs } from "./aboutUs/AboutUs";
import { Technologies } from "./technologies/Technologies";
import { Vacancy } from "./vacancy/Vacancy";
import { Box } from "@chakra-ui/react";
import { Services } from "./services/Services";
const Wrapper = ({ children }: { children: ReactNode }) => (
  <Box
    scrollSnapAlign="start"
    minH="100vh"
    mb={[0, 0, "64px"]}
    p={[0, 0, "64px 0"]}
  >
    {children}
  </Box>
);
export const MainContent = () => {
  const { t } = useTranslation();
  return (
    <>
      <Box
        scrollSnapAlign="start"
        minH="100vh"
        mb={[0, 0, "64px"]}
        p="64px 0 0"
      >
        <ContentBlock label={t("AboutUs.title")}>
          <AboutUs />
        </ContentBlock>
      </Box>
      <Wrapper>
        <ContentBlock label={t("Services.title")}>
          <Services />
        </ContentBlock>
      </Wrapper>
      <Wrapper>
        <ContentBlock label={t("Technologies.title")}>
          <Technologies />
        </ContentBlock>
      </Wrapper>
      <Wrapper>
        <ContentBlock label={t("Vacancy.title")}>
          <Vacancy />
        </ContentBlock>
      </Wrapper>
    </>
  );
};
