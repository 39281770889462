import React, { useState } from "react";
import { Select } from "@chakra-ui/react";

import i18n from "../i18n";

export const LanguageSelector = () => {
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

  const handleChangeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    i18n.changeLanguage(event.target.value);
    setCurrentLanguage(event.target.value);
  };

  return (
    <Select value={currentLanguage} onChange={handleChangeValue}>
      <option value="ru">RU</option>
      <option value="en">EN</option>
    </Select>
  );
};
