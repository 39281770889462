import React, { ReactElement } from "react";

import { Box, Flex, Heading, Stack, useColorModeValue } from "@chakra-ui/react";

interface FrontCardProps {
  heading?: string;
  description?: string;
  icon?: ReactElement;
}

export const FrontCard = ({ heading, description, icon }: FrontCardProps) => {
  return (
    <Box
      w="275px"
      h="275px"
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}
    >
      <Stack align={description ? "start" : "center"} spacing={2}>
        <Flex
          align={"center"}
          justify={"center"}
          bg={useColorModeValue("gray.100", "gray.700")}
        >
          {icon}
        </Flex>
        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
        </Box>
      </Stack>
    </Box>
  );
};
