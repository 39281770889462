import * as React from "react";
import {
  ChakraProvider,
  StyleFunctionProps,
  extendTheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

import { MainPage } from "./blocks/MainPage";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  styles: {
    global: (props: StyleFunctionProps) => ({
      body: {
        bg: mode("#f0fff4bf", "gray.800")(props),
      },
    }),
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>{<MainPage />}</ChakraProvider>
);
