import React from "react";

import { Box, Container, Flex, Icon } from "@chakra-ui/react";
import {
  FcAbout,
  FcSerialTasks,
  FcDonate,
  FcConferenceCall,
  FcFactory,
  FcMindMap,
} from "react-icons/fc";
import { useTranslation } from "react-i18next";
import { Card } from "./componets/Card";

export const AboutUs = () => {
  const { t } = useTranslation();
  return (
    <Box p={4}>
      <Container maxW={"5xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <Card
            heading={t("AboutUs.Area.heading")}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={t("AboutUs.Area.info")}
          />
          <Card
            heading={t("AboutUs.Profile.heading")}
            icon={<Icon as={FcFactory} w={10} h={10} />}
            description={t("AboutUs.Profile.info")}
          />
          <Card
            heading={t("AboutUs.Customers.heading")}
            icon={<Icon as={FcDonate} w={10} h={10} />}
            description={t("AboutUs.Customers.info")}
          />
          <Card
            heading={t("AboutUs.Works.heading")}
            icon={<Icon as={FcMindMap} w={10} h={10} />}
            description={t("AboutUs.Works.info")}
          />
          <Card
            heading={t("AboutUs.Team.heading")}
            icon={<Icon as={FcConferenceCall} w={10} h={10} />}
            description={t("AboutUs.Team.info")}
          />
          <Card
            heading={t("AboutUs.Tasks.heading")}
            icon={<Icon as={FcSerialTasks} w={10} h={10} />}
            description={t("AboutUs.Tasks.info")}
          />
        </Flex>
      </Container>
    </Box>
  );
};
