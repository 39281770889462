import React, { ReactNode, useState } from "react";
import "./styles.css";
import { Box } from "@chakra-ui/react";

type FlipCardProp = {
  FrontSide: ReactNode;
  BackSide: ReactNode;
};

export const FlipCard = ({ FrontSide, BackSide }: FlipCardProp) => {
  const [flip, setFlip] = useState(false);
  const flipCard = () => {
    setFlip(!flip);
  };

  return (
    <Box className={`flip-card ${flip !== true ? "front-flip" : "back-flip"}`}>
      <Box className="flip-card-inner" onClick={flipCard} cursor={"pointer"}>
        <Box className="flip-card-front">{FrontSide}</Box>
        <Box className="flip-card-back">{BackSide}</Box>
      </Box>
    </Box>
  );
};
