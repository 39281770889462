import React, { ReactElement } from "react";
import { Box, Container, Flex, useColorModeValue } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface IconCardProp {
  iconFront: ReactElement;
  iconBack: ReactElement;
}

const settings = {
  dots: false,
  arrows: false,
  fade: true,
  infinite: true,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export const FlipIconCard = ({ iconFront, iconBack }: IconCardProp) => {
  return (
    <Flex
      h={"275px"}
      w={"275px"}
      borderWidth="1px"
      borderRadius="lg"
      justifyContent={"center"}
      bg={useColorModeValue("gray.100", "gray.400")}
    >
      <Box overflow={"hidden"} h={"275px"} w={"275px"}>
        <Slider {...settings}>
          <Container h={"275px"} w={"275px"}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              height="100%"
            >
              {iconFront}
            </Box>
          </Container>
          <Container h={"275px"} w={"275px"}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              textAlign="center"
              height="100%"
            >
              {iconBack}
            </Box>
          </Container>
        </Slider>
      </Box>
    </Flex>
  );
};
