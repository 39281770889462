import { Box, Container, Flex, chakra } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { ServicesCard } from "./components/ServicesCard";

import qa from "./icons/qa.svg";
import support from "./icons/support.svg";
import development from "./icons/development.svg";
import analytics from "./icons/analytics.svg";

export const Services = () => {
  const { t } = useTranslation();

  return (
    <Box p={4}>
      <Container maxW={"4xl"} mt={12}>
        <Flex flexWrap="wrap" gridGap={6} justify="center">
          <ServicesCard
            heading={t("Services.Analytics.heading")}
            icon={<chakra.img src={analytics} w={"200px"} h={"180px"} />}
            description={t("Services.Analytics.info")}
          />
          <ServicesCard
            heading={t("Services.Development.heading")}
            icon={<chakra.img src={development} w={"200px"} h={"180px"} />}
            description={t("Services.Development.info")}
          />
          <ServicesCard
            heading={t("Services.Support.heading")}
            icon={<chakra.img src={support} w={"200px"} h={"180px"} />}
            description={t("Services.Support.info")}
          />
          <ServicesCard
            heading={t("Services.Qa.heading")}
            icon={<chakra.img src={qa} w={"200px"} h={"180px"} />}
            description={t("Services.Qa.info")}
          />
        </Flex>
      </Container>
    </Box>
  );
};
